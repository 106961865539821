<template>
<AppLayout>
    <template v-slot:appContent>
        <section class="users-list-wrapper mt-4">
            <div class="row justify-content-center">
                <div class="col-xl-8 col-lg-10 col-12">
                    <div class="card p-md-2 p-0">
                        <div class="card-body pt-3 pb-5">
                            <div class="row justify-content-center">
                                <div class="col-xl-8">
                                    <h4 class="mb-3">Update Franchisee Payment Reference Settings</h4>
                                    <div class="d-flex w-100 align-items-end mb-4">
                                        <div class="w-100 mr-1">
                                            <label for="">{{(franchiseePaymentReferencePrefix.key.replaceAll("_", " ").replace("franchisee", ""))}}</label>
                                            <input type="text" class="form-control" placeholder="" v-model="franchiseePaymentReferencePrefix.value">
                                        </div>
                                        <div>
                                            <button class="btn btn-primary px-lg-4 px-2" type="submit" @click="updatePrefixSetting">Update</button>
                                        </div>
                                    </div>
                                    <div class="d-flex w-100 align-items-end">
                                        <div class="w-100 mr-1">
                                            <label for="">{{(franchiseePaymentReferenceSuffix.key.replaceAll("_", " ").replace("franchisee", ""))}}</label>
                                            <input type="text" class="form-control" placeholder="" v-model="franchiseePaymentReferenceSuffix.value">
                                        </div>
                                        <div>
                                            <button class="btn btn-primary px-lg-4 px-2" type="submit" @click="updateSuffixSetting">Update</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </template>
</AppLayout>
</template>

<script>
// appLayout
import AppLayout from '../../../../layouts/backEnd/AppLayout.vue'

// mixins
import ShowToastMessage from '@/components/backEnd/mixins/ShowToastMessage';
import Loader from '@/components/backEnd/mixins/Loader';
import Authorization from '@/components/backEnd/mixins/Authorization';
// core packages
import {
    mapActions,
    mapGetters,
} from "vuex";

export default {
    name: 'FranchiseeSettings',
    mixins: [ShowToastMessage, Loader, Authorization],
    components: {
        AppLayout,
    },
    data() {
        return {
            getSettingsParams: {
                type: ['franchisee_payment'],
            },
            franchiseePaymentReferencePrefix: {
                id: '',
                key: '',
                type: 'franchisee_payment',
                value: ''
            },
            franchiseePaymentReferenceSuffix: {
                id: '',
                key: '',
                type: 'franchisee_payment',
                value: ''
            },
        };
    },

    computed: {
        ...mapGetters({
            settingFranchiseePaymentReferencePrefix: "appSettings/settingFranchiseePaymentReferencePrefix",
            settingFranchiseePaymentReferenceSuffix: "appSettings/settingFranchiseePaymentReferenceSuffix",
        }),
    },

    methods: {
        ...mapActions({
            getSettings: "appSettings/getSettings",
            putSetting: "appSettings/putSetting",
        }),

        async getSettingList() {
            this.loader(true);
            const response = await this.getSettings(this.getSettingsParams);
            this.loader(false);
            if (response.status === 200) {

                this.franchiseePaymentReferencePrefix.key = this.settingFranchiseePaymentReferencePrefix.key;
                this.franchiseePaymentReferencePrefix.id = this.settingFranchiseePaymentReferencePrefix.id;
                this.franchiseePaymentReferencePrefix.type = this.settingFranchiseePaymentReferencePrefix.type;
                this.franchiseePaymentReferencePrefix.value = this.settingFranchiseePaymentReferencePrefix.value;

                this.franchiseePaymentReferenceSuffix.key = this.settingFranchiseePaymentReferenceSuffix.key;
                this.franchiseePaymentReferenceSuffix.id = this.settingFranchiseePaymentReferenceSuffix.id;
                this.franchiseePaymentReferenceSuffix.type = this.settingFranchiseePaymentReferenceSuffix.type;
                this.franchiseePaymentReferenceSuffix.value = this.settingFranchiseePaymentReferenceSuffix.value;

            }
            if (response.message) {
                this.showToastMessage(response);
            }
        },

        //update prefix
        prefixValidationChecker() {
            console.log(!this.franchiseePaymentReferencePrefix.value, "this.franchiseePaymentReferencePrefix.value");
            if (!this.franchiseePaymentReferencePrefix.value) {
                this.showToastMessage({
                    message: `prefix value can't be empty`,
                    type: 'error',
                });
                return false;
            }
            return true;
        },
        async updatePrefixSetting() {
            if (this.prefixValidationChecker()) {
                const data = {
                    type: this.franchiseePaymentReferencePrefix.type,
                    key: this.franchiseePaymentReferencePrefix.key,
                    value: this.franchiseePaymentReferencePrefix.value,
                }

                const dataObj = {
                    id: this.franchiseePaymentReferencePrefix.id,
                    data: data
                }

                this.loader(true);
                const response = await this.putSetting(dataObj);
                this.loader(false);
                if (response.status === 200) {
                    this.showToastMessage({
                        type: 'success',
                        message: `Updated Successfully  `
                    });
                    return;
                }

                if (response.message) {
                    this.showToastMessage(response);
                }
            }

        },

        //update suffix
        suffixValidationChecker() {
            if (!this.franchiseePaymentReferenceSuffix.value) {
                this.showToastMessage({
                    message: `suffix value can't be empty`,
                    type: 'error',
                });
                return false;
            }
            return true;
        },
        async updateSuffixSetting() {
            if (this.suffixValidationChecker()) {

                const data = {
                    type: this.franchiseePaymentReferenceSuffix.type,
                    key: this.franchiseePaymentReferenceSuffix.key,
                    value: this.franchiseePaymentReferenceSuffix.value,
                }

                const dataObj = {
                    id: this.franchiseePaymentReferenceSuffix.id,
                    data: data
                }

                this.loader(true);
                const response = await this.putSetting(dataObj);
                this.loader(false);
                if (response.status === 200) {

                    this.showToastMessage({
                        type: 'success',
                        message: `Updated Successfully  `
                    });
                    return;
                }

                if (response.message) {
                    this.showToastMessage(response);
                }
            }
        }
    },

    async mounted() {
        this.loader(true);
        await this.getSettingList();
        this.loader(false);
    },
}
</script>

<style scoped>
</style>
